import StatContent from './StatContent';
import VisitType from "../model/VisitType";
import StatDataType from "../model/StatDataType";
import {normalizeLanguage} from "../utils/language";

class StatData {
    account: string
    platform: string
    visit: VisitType
    isLogged: boolean
    userId: string

    constructor(account: string, platform: string, visit: VisitType, isLogged: boolean, userId: string) {
        this.account = account;
        this.platform = platform;
        this.visit = visit;
        this.isLogged = isLogged;
        this.userId = userId;
    }

    async serialize(): Promise<StatDataType> {
        const statData = {
            token: this.account,
            platform: this.platform,
            visit: this.visit.id,
            referrer: document.referrer,
            logged: this.isLogged,
            userId: this.userId,
            uri: global.location.protocol + "//" + global.location.host + global.location.pathname,
            content: await StatContent.generate(),
            locale: normalizeLanguage(document.documentElement.lang),
        };

        const campaign = (new URLSearchParams(global.location.search)).get('utm_campaign');
        if (campaign) {
            Object.assign(statData, {
                campaign: campaign
            })
        }

        return statData;
    }
}

export default StatData;
